import { Color, Object3D } from 'three';
import type Instance from '@giro3d/giro3d/core/Instance';
import LUT from 'giro3d_extensions/LUT';
import {
    Attribute,
    ColoringMode,
    HasAttributes,
    HasColoringMode,
    HasOpacity,
    HasOverlayColor,
    HasRadius,
    LayerState,
} from 'types/LayerState';
import ColorMap, { COLORMAP_BOUNDSMODE, COLORMAP_NAMES, getLUT } from 'types/ColorMap';
import { Unit } from 'types/common';
import {
    DEFAULT_CABLE_PIPELINE_SETTINGS,
    DEFAULT_GEOJSON_SETTINGS,
    GEOJSON_AMPLITUDE_MODE,
    LAYER_TYPES,
} from 'services/Constants';
import * as layersSlice from 'redux/layers';
import { Dispatch } from 'store';
import IsHoverable from 'giro3d_extensions/layers/IsHoverable';
import HoveredItem from 'types/HoveredItem';
import ThreejsGroupLayer, { Settings as BaseSettings } from '../ThreejsGroupLayer';
import LinearEntity from './LinearEntity';
import Source, { Description, Property } from './Source';
import GeometryBuilder from './GeometryBuilder';
import AmplitudeEntity from './AmplitudeEntity';
import LayerStateObserver from '../LayerStateObserver';
import { LayerEventMap, ConstructorParams as BaseConstructorParams } from '../Layer';

type LineLayerState = LayerState &
    HasAttributes &
    HasOpacity &
    HasRadius &
    HasOverlayColor &
    HasColoringMode &
    HasAttributes;

export interface ConstructorParams extends BaseConstructorParams {
    dispatch: Dispatch;
    source: Source;
    datasetType: LAYER_TYPES;
    builder: GeometryBuilder;
    readableName: string;
    createEntityOnInit: boolean;
}

export type AmplitudeSetting = {
    amplitudeMode: string;
    amplitudeSpatialRange: number;
    isShowingAmplitude: boolean;
};

export interface Settings extends BaseSettings {
    currentPropkey: string;
    overlayColor: Color;
    radius: number;
}

function getDefaultColorMap(prop: Property): ColorMap {
    return {
        boundsMode: COLORMAP_BOUNDSMODE.DATA,
        customMax: prop.max,
        customMin: prop.min,
        discrete: false,
        invert: false,
        name: COLORMAP_NAMES.SPECTRAL,
    };
}

/**
 * Base class for linear layers (pipelines, cables, tracklines, etc).
 */
export default abstract class LineLayer<
        TSettings extends Settings = Settings,
        TSource extends LineLayerState = LineLayerState,
    >
    extends ThreejsGroupLayer<TSettings, LayerEventMap, TSource>
    implements IsHoverable
{
    readonly isHoverable = true as const;
    readonly isLineLayer = true;
    private _isLoading = false;
    protected _lineEntity: LinearEntity;
    protected _isLoadingEntity = false;
    private _amplitudeEntity: AmplitudeEntity;
    protected propkeys: string[] = [];
    protected readonly _geometrySource: Source;
    protected readonly _builder: GeometryBuilder;
    private readonly _datasetType: LAYER_TYPES;
    protected type: string;
    protected readonly _readableName: string;
    private _amplitudeLoaded: boolean;
    private _sourceDescription: Description;
    private readonly _createEntityOnInit: boolean;
    protected _colorMapProperties: { min: number; max: number; propertyName: string; colors: Color[] };
    protected _coloringMode: ColoringMode = ColoringMode.OverlayColor;
    private _amplitudeSetting: AmplitudeSetting;

    constructor(params: ConstructorParams) {
        super(params);

        this._amplitudeLoaded = false;

        this._geometrySource = params.source;
        this._builder = params.builder;
        this._readableName = params.readableName;
        this._datasetType = params.datasetType;

        const settings = this.settings;
        settings.overlayColor = new Color(DEFAULT_CABLE_PIPELINE_SETTINGS.OVERLAY_COLOR);
        this._amplitudeSetting = {
            amplitudeSpatialRange: DEFAULT_GEOJSON_SETTINGS.AMPLITUDE_SPACTIAL_RANGE,
            amplitudeMode: DEFAULT_GEOJSON_SETTINGS.AMPLITUDE_MODE,
            isShowingAmplitude: DEFAULT_GEOJSON_SETTINGS.AMPLITUDE_SHOWN,
        };

        this._colorMapProperties = {
            min: 0,
            max: 1,
            colors: [],
            propertyName: null,
        };

        this._createEntityOnInit = params.createEntityOnInit;

        this.assignInitialValues();
    }

    setOpacity(value: number): void {
        super.setOpacity(value);
        if (this._lineEntity) {
            this._lineEntity.opacity = value;
        }
        if (this._amplitudeEntity) {
            this._amplitudeEntity.opacity = value;
        }
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<TSource>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getRadius(this._layerState), (v) => this.setRadius(v));
        observer.subscribe(layersSlice.getOverlayColor(this._layerState), (v) => this.setOverlayColor(v));
        observer.subscribe(layersSlice.getCurrentColoringMode(this._layerState), (v) => this.setColoringMode(v));
        observer.subscribe(layersSlice.getActiveAttributeAndColorMap(this._layerState), (v) =>
            this.setColormapProperties(v)
        );
    }

    hover(hover: boolean) {
        this.setBrightness(hover ? 0.25 : 0);
        this.notifyLayerChange();
    }

    getHoverInfo(): HoveredItem {
        return {
            name: this._readableName,
            itemType: this._datasetType,
        };
    }

    async doSetVisibility(visible: boolean) {
        await super.doSetVisibility(visible);
        if (this._lineEntity) {
            this._lineEntity.visible = visible;
            if (!visible) {
                this._lineEntity.dispose();
            }
        }
        if (this._amplitudeEntity) {
            this._amplitudeEntity.visible = visible;
        }
    }

    protected notifyLayerChange(): void {
        super.notifyLayerChange();
        const instance: Instance = this._giro3dInstance;
        instance.notifyChange(this._lineEntity);
        if (this._amplitudeLoaded) {
            instance.notifyChange(this._amplitudeEntity);
        }
    }

    protected onClickableObjectCreated(object: Object3D) {
        object.userData.datasetId = this.datasetId;
        object.userData.sourceFileId = this.sourceFileId;
    }

    private createAmplitude() {
        this._amplitudeEntity = new AmplitudeEntity(this._geometrySource, this.datasetId, this.sourceFileId);
        this._amplitudeLoaded = true;
        this._giro3dInstance.add(this._amplitudeEntity);
        this.object3d.add(this._amplitudeEntity.object3d);
    }

    private removeAmplitude() {
        this._giro3dInstance.remove(this._amplitudeEntity);
        this._amplitudeEntity = null;
        this._amplitudeLoaded = false;
    }

    protected showAmplitude(value: boolean) {
        this._amplitudeSetting.isShowingAmplitude = value;

        if (this.initialized) {
            if (value) {
                if (this._coloringMode === ColoringMode.Colormap && !this._amplitudeLoaded) {
                    this.createAmplitude();
                    this.updateAmplitude();
                }
            } else if (this._amplitudeLoaded) {
                this.removeAmplitude();
            }
            this.notifyLayerChange();
        }
    }

    private updateAmplitude() {
        if (!this._amplitudeLoaded) {
            this.createAmplitude();
        }
        const { min, max, colors, propertyName } = this._colorMapProperties;
        const amplitude = this._amplitudeSetting;

        this._amplitudeEntity.setParameters(
            propertyName,
            amplitude.amplitudeMode,
            min,
            max,
            amplitude.amplitudeSpatialRange,
            new LUT(colors, min, max),
            'solid'
        );
    }

    protected setAmplitudeMode(value: GEOJSON_AMPLITUDE_MODE) {
        this._amplitudeSetting.amplitudeMode = value;

        if (this.initialized) {
            if (this._amplitudeLoaded) {
                this.updateAmplitude();
            }
            this.notifyLayerChange();
        }
    }

    protected setAmplitudeRange(value: number) {
        this._amplitudeSetting.amplitudeSpatialRange = value;

        if (this.initialized) {
            if (this._amplitudeLoaded) {
                this.updateAmplitude();
            }
            this.notifyLayerChange();
        }
    }

    protected setColoringMode(mode: ColoringMode): void {
        this._coloringMode = mode;
        if (!this.initialized) {
            return;
        }

        switch (mode) {
            case ColoringMode.Colormap:
                this.applyColorMap();
                break;
            default:
                this.applyOverlay();
                break;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    protected setColormapProperties(arg: { attribute: Attribute; colorMap: ColorMap }): void {
        if (!arg) {
            return;
        }

        const { attribute, colorMap } = arg;

        let min: number = attribute.min;
        let max: number = attribute.max;

        if (colorMap) {
            if (colorMap.boundsMode === COLORMAP_BOUNDSMODE.CUSTOM) {
                min = colorMap.customMin;
                max = colorMap.customMax;
            }

            const colors = getLUT(colorMap, { samples: 256 });
            this._colorMapProperties = { min, max, propertyName: attribute.id, colors };

            if (this._coloringMode === ColoringMode.Colormap) {
                this.applyColorMap();
            }
        }
    }

    protected setOverlayColor(color?: Color) {
        this.settings.overlayColor = color;
        if (this._coloringMode === ColoringMode.OverlayColor) {
            this.applyOverlay();
        }
    }

    protected setRadius(radius: number) {
        this.settings.radius = radius;

        if (this._lineEntity) {
            this._lineEntity.setRadius(radius);
        } else {
            this._builder.setRadius(radius);
        }

        this.notifyLayerChange();
    }

    protected applyOverlay() {
        this._lineEntity?.setColor(this.settings.overlayColor);

        if (this._amplitudeLoaded) {
            this.removeAmplitude();
        }
    }

    protected applyColorMap() {
        this._lineEntity?.setColorMap(this._colorMapProperties);

        if (this._amplitudeSetting.isShowingAmplitude) {
            this.updateAmplitude();
        }
    }

    protected initEntity() {
        this._isLoadingEntity = true;

        const entity = new LinearEntity({
            datasetId: this.datasetId,
            fileId: this.sourceFileId,
            source: this._geometrySource,
            geometryBuilder: this._builder,
            overlayColor: this.settings.overlayColor,
        });

        entity.userData.datasetId = this.datasetId;
        entity.userData.sourceFileId = this.sourceFileId;

        this._giro3dInstance.add(entity);

        this._lineEntity = entity;
        this.object3d.add(this._lineEntity.object3d);

        this.assignInitialValues();

        this._dispatch(layersSlice.setPathLoaded({ layer: this._layerState, value: true }));

        this._isLoadingEntity = false;
    }

    protected override async initOnce() {
        this._isLoading = true;

        if (!this.initialized) {
            await super.initOnce();

            this._sourceDescription = await this._geometrySource.getDescription();

            this.propkeys = [...this._sourceDescription.properties.keys()];

            if (!this._colorMapProperties.propertyName) {
                const attributes: Attribute[] = [];
                const colorMaps = new Map<string, ColorMap>();

                for (const [key, prop] of this._sourceDescription.properties) {
                    const attribute: Attribute = {
                        id: key,
                        name: prop.name,
                        min: prop.min,
                        max: prop.max,
                        unit: Unit.None,
                    };

                    colorMaps.set(attribute.id, getDefaultColorMap(prop));

                    attributes.push(attribute);
                }

                if (attributes.length > 0) {
                    const layer = this._layerState;
                    this._dispatch(layersSlice.setAttributes({ layer, value: attributes }));

                    for (const [attributeId, colorMap] of colorMaps) {
                        this._dispatch(layersSlice.setAttributeColorMap({ layer, attributeId, value: colorMap }));
                    }
                }
            }

            this.object3d.name = `${this._readableName} (${this.type})`;

            if (this._createEntityOnInit) {
                this.initEntity();
            }
        }

        this._isLoading = false;
        this.notifyLayerChange();
    }

    protected override onDispose() {
        super.onDispose();
        if (this._lineEntity) {
            this._giro3dInstance.remove(this._lineEntity);
        }
        if (this._amplitudeEntity) {
            this._giro3dInstance.remove(this._amplitudeEntity);
        }
    }

    getLoading() {
        return this._isLoading;
    }

    protected setBrightness(brightness: number) {
        this._lineEntity?.setBrightness(brightness);
        this._lineEntity?.showInFront(brightness > 0);
        this._amplitudeEntity?.setBrightness(brightness);
        this._amplitudeEntity?.showInFront(brightness > 0);
    }

    getGeometry() {
        return this._geometrySource.getGeometries();
    }

    getSource() {
        return this._geometrySource;
    }
}
