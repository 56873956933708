import type ElevationRange from '@giro3d/giro3d/core/ElevationRange';
import {
    GEOJSON_AMPLITUDE_MODE,
    FILTER_DISPLAY_MODE,
    LAYER_TYPES,
    LAYER_DATA_TYPES,
    ORDER_MODE,
} from 'services/Constants';
import ColorMap, { COLORMAP_BOUNDSMODE } from 'types/ColorMap';
import {
    AttributeId,
    AttributeName,
    DatasetId,
    Normalized,
    OLFeatureId,
    SerializableColor,
    SourceFileId,
    Unit,
} from './common';

/**
 * ## The `LayerState` type.
 *
 * The `LayerState` type stores a `Dataset`'s client-specific logic and state,
 * such as styling opacity, etc, that the backend does not know or does not care about.
 *
 * ### Type composition
 *
 * There is no type hierarchy for layers, as Redux mandates immutable,
 * simple [Plain Old Javascript Objects (POJO)](https://masteringjs.io/tutorials/fundamentals/pojo).
 *
 * Instead, to express different capabilities of various datasets,
 * we compose the `LayerState` type with trait-like types (see below),
 * such as `HasOpacity`, `HasProgress`, etc:
 *
 * ```
 * // Express a layer type that support both opacity and progress reporting.
 * type MyCustomLayerType = LayerState & HasOpacity & HasProgress;
 * ```
 *
 * ### Serialization
 *
 * Since all the types defined in this file are POJOs, they are all trivially serializable to
 * and from JSON.
 *
 * **Warning**: Only use POJOs, not `Map`s to represent hashmap-like objects.
 */
export type LayerState = {
    datasetId: DatasetId;
    datasetType: LAYER_TYPES;
    dataType: LAYER_DATA_TYPES;
    /** Is this dataset visible in the 3D views ? */
    visible: boolean;
};

/** Perform a compile-time cast of the layer to `Trait`. */
function as<Trait>(layer: LayerState): LayerState & Trait {
    return layer as LayerState & Trait;
}

function check<Trait>(layer: LayerState | undefined, predicate: (v: Trait) => boolean): layer is LayerState & Trait {
    if (layer) {
        return predicate(as<Trait>(layer)) ?? false;
    }
    return false;
}

/**
 * Trait for layers that can report a loading progress.
 */
export type HasProgress = {
    hasProgress: true;
};

/**
 * Check that the given layer has the {@link HasProgress} trait.
 */
export function hasProgress(layer: LayerState): layer is LayerState & HasProgress {
    return check<HasProgress>(layer, (l) => l.hasProgress);
}

/**
 * Trait for layers that support variable opacity.
 */
export type HasOpacity = {
    hasOpacity: true;
    /** The opacity of the layer. */
    opacity: Normalized;
};

/**
 * Check that the given layer has the {@link HasOpacity} trait.
 */
export function hasOpacity(layer: LayerState): layer is LayerState & HasOpacity {
    return check<HasOpacity>(layer, (l) => l.hasOpacity);
}

/**
 * Trait for layers that support an overlay (solid) color.
 */
export type HasOverlayColor = {
    hasOverlayColor: true;
    overlayColor: SerializableColor;
};

/**
 * Check that the given layer has the {@link HasOverlayColor} trait.
 */
export function hasOverlayColor(layer: LayerState): layer is LayerState & HasOverlayColor {
    return check<HasOverlayColor>(layer, (l) => l.hasOverlayColor);
}

/**
 * Attribute names that are already known in SCOPE.
 */
export enum WellKnownAttributeNames {
    Intensity = 'Intensity',
    Gradient = 'Gradient',
    Slope = 'Slope',
    Aspect = 'Aspect',
    Elevation = 'Elevation',
    Seismic = 'Seismic',
    Electromagnetic = 'EM',
}

export type Attribute = {
    name: AttributeName | WellKnownAttributeNames;
    id: AttributeId | WellKnownAttributeNames;
    unit: Unit | string;
    min: number;
    max: number;
};

export function getAttributeMinMax(attribute: Attribute, colorMap?: ColorMap): { min: number; max: number } {
    if (colorMap && colorMap.boundsMode === COLORMAP_BOUNDSMODE.CUSTOM) {
        return {
            min: colorMap.customMin,
            max: colorMap.customMax,
        };
    }

    return {
        min: attribute.min,
        max: attribute.max,
    };
}

export enum ColoringMode {
    OverlayColor = 'overlay',
    Colormap = 'colormap',
}

export type HasColoringMode = {
    hasColoringMode: true;
    currentColoringMode: ColoringMode;
    coloringModes: ColoringMode[];
};

/**
 * Check that the given layer has the {@link HasColoringMode} trait.
 */
export function hasColoringMode(layer: LayerState): layer is LayerState & HasColoringMode {
    return check<HasColoringMode>(layer, (l) => l.hasColoringMode);
}

export enum DataPointMode {
    /**
     * Displays data points in a continuous coloring.
     */
    Continuous = 'continuous',
    /**
     * Displays data points as discrete anodes.
     */
    Anode = 'anode',
}

/**
 * Trait for datasets that support multiple ways of displaying data points.
 */
export type HasDataPointMode = {
    hasHasDataPointMode: true;
    currentDataPointMode: DataPointMode;
    availableDataPointModes: DataPointMode[];
};

/**
 * Check that the given layer has the {@link HasDataPointMode} trait.
 */
export function hasDataPointMode(layer: LayerState): layer is LayerState & HasDataPointMode {
    return check<HasDataPointMode>(layer, (l) => l.hasHasDataPointMode);
}

/**
 * Trait for layer attributes.
 */
export type HasAttributes = {
    hasAttributes: true;
    attributes: Attribute[];
    /**
     * Per-attribute colormaps.
     */
    colorMaps: Record<AttributeName, ColorMap>;
    activeAttribute?: string;
    /** Display this dataset's legend in the 3D view */
    pinLegend: boolean;
    unrenderedAttributes: string[];
};

/**
 * Check that the given layer has the {@link HasAttributes} trait.
 */
export function hasAttributes(layer: LayerState): layer is LayerState & HasAttributes {
    return check<HasAttributes>(layer, (l) => l.hasAttributes);
}

/**
 * Traits for types that support opacity curves in their colormap.
 *
 * Note: although the curve is part of the {@link ColorMap} definition (for convenience),
 * its support is not guaranteed for all data types, hence this trait.
 */
export type HasOpacityCurve = {
    hasOpacityCurve: true;
};

/**
 * Check that the given layer has the {@link HasOpacityCurve} trait.
 */
export function hasOpacityCurve(layer: LayerState): layer is LayerState & HasOpacityCurve {
    return check<HasOpacityCurve>(layer, (l) => l.hasOpacityCurve);
}

/**
 * Trait for layers that are displayed as point clouds.
 */
export type IsPointCloud = {
    isPointCloud: true;
    pointCloudSize: number;
    sseThreshold: number;
};

/**
 * Check that the given layer has the {@link IsPointCloud} trait.
 */
export function isPointCloud(layer: LayerState): layer is LayerState & IsPointCloud {
    return check<IsPointCloud>(layer, (l) => l.isPointCloud);
}

/**
 * Trait for layers that can be masked by, or mask, other layers.
 */
export type HasMasks = {
    isMaskable: true;
    masks: DatasetId[];
};

/**
 * Check that the given layer has the {@link HasMasks} trait.
 */
export function hasMasks(layer: LayerState): layer is LayerState & HasMasks {
    return check<HasMasks>(layer, (l) => l.isMaskable);
}

/**
 * Trait for layers that have a radius.
 */
export type HasRadius = {
    hasRadius: true;
    radius: number;
};

/**
 * Check that the given layer has the {@link HasRadius} trait.
 */
export function hasRadius(layer: LayerState): layer is LayerState & HasRadius {
    return check<HasRadius>(layer, (l) => l.hasRadius);
}

/**
 * Trait for layers that can display arrows.
 */
export type HasArrows = {
    hasArrows: true;
    showArrows: boolean;
    arrowSpacing: number;
    arrowScale: number;
};

/**
 * Check that the given layer has the {@link HasArrows} trait.
 */
export function hasArrows(layer: LayerState): layer is LayerState & HasArrows {
    return check<HasArrows>(layer, (l) => l.hasArrows);
}

export type AmplitudeSetting = {
    showAmplitude: boolean;
    mode: GEOJSON_AMPLITUDE_MODE;
    range: number;
};

/**
 * Trait for layers that can display an amplitude of data.
 */
export type HasAmplitude = {
    hasAmplitude: true;
    perAttributeAmplitudeSettings: Record<string, AmplitudeSetting>;
};

/**
 * Check that the given layer has the {@link HasAmplitude} trait.
 */
export function hasAmplitude(layer: LayerState): layer is LayerState & HasAmplitude {
    return check<HasAmplitude>(layer, (l) => l.hasAmplitude);
}

/**
 * Trait for seismic datasets.
 */
export type HasSeismicPlane = {
    isSeismic: true;
    speedModuleMs: number;
    intensityFilter: number;
    staticCorrection: boolean;
    filterTransparency: boolean;
    seismicOffset: number;
    activeFile: SourceFileId;
    inlineDirection: number;
    inlineTolerance: number;
    crosslineDirection: number;
    crosslineTolerance: number;
    inlineTerm: string;
    crosslineTerm: string;
    lineOrderMode: ORDER_MODE;
};

/**
 * Check that the given layer has the {@link HasSeismicPlane} trait.
 */
export function hasSeismicPlane(layer: LayerState): layer is LayerState & HasSeismicPlane {
    return check<HasSeismicPlane>(layer, (l) => l.isSeismic);
}

/**
 * Traits for layers that can be draped to, or undraped from, elevation layers.
 */
export type HasDraping = {
    hasDraping: true;
    isDraped: boolean;
    zOffset: number;
    enableClippingRange: boolean;
    clippingRange: ElevationRange;
};

/**
 * Check that the given layer has the {@link HasDraping} trait.
 */
export function hasDraping(layer: LayerState): layer is LayerState & HasDraping {
    return check<HasDraping>(layer, (l) => l.hasDraping);
}

/**
 * Trait for layers that can be displayed in the minimap.
 */
export type CanShowInMinimap = {
    canShowInMinimap: true;
    showInMinimap: boolean;
};

/**
 * Check that the given layer has the {@link CanShowInMinimap} trait.
 */
export function canShowInMinimap(layer: LayerState): layer is LayerState & CanShowInMinimap {
    return check<CanShowInMinimap>(layer, (l) => l.canShowInMinimap);
}

/**
 * Trait for layers that can display their footprint.
 */
export type HasFootprint = {
    hasFootprint: true;
    showFootprint: boolean;
    showFootprintLabels: boolean;
    footprintColor: SerializableColor;
};

/**
 * Check that the given layer has the {@link HasFootprint} trait.
 */
export function hasFootprint(layer: LayerState): layer is LayerState & HasFootprint {
    return check<HasFootprint>(layer, (l) => l.hasFootprint);
}

/**
 * Trait for layers that have a borehole.
 */
export type HasBorehole = {
    hasBorehole: true;
    /**
     * Toggle variable radii in borehole representation.
     */
    variableRadii: boolean;
};

/**
 * Check that the given layer has the {@link HasBorehole} trait.
 */
export function hasBorehole(layer: LayerState): layer is LayerState & HasBorehole {
    return check<HasBorehole>(layer, (l) => l.hasBorehole);
}

/**
 * Trait for layers that can toggle individual source files. If this trait is not present,
 * it means that only the entire dataset can be toggled.
 */
export type CanToggleSourceFiles = {
    canToggleSourceFiles: true;
};

/**
 * Check that the given layer has the {@link CanToggleSourceFiles} trait.
 */
export function canToggleSourceFiles(layer: LayerState): layer is LayerState & CanToggleSourceFiles {
    return check<CanToggleSourceFiles>(layer, (l) => l.canToggleSourceFiles);
}

export type VectorStyle = {
    fillOpacity: number;
    lineWidth: number;
    pointSize: number;
    fillColor: SerializableColor;
    borderColor: SerializableColor;
    borderOpacity: number;
    borderWidth: number;
};

export type FeatureFilter = {
    name: string;
    mode: FILTER_DISPLAY_MODE;
    features: OLFeatureId[];
    style: VectorStyle;
};

/**
 * Traits for layers that support vector styling.
 */
export type HasVectorStyle = {
    hasVectorStyle: true;
    /**
     * The layer has been entirely loaded (including in the 3D view).
     * // FIXME temporary workaround for components that still rely on the Giro3D state.
     */
    vectorFeaturesReady: boolean;
    vectorStyle: VectorStyle;
    featureFilter: FeatureFilter;
};

/**
 * Check that the given layer has the {@link HasVectorStyle} trait.
 */
export function hasVectorStyle(layer: LayerState): layer is LayerState & HasVectorStyle {
    return check<HasVectorStyle>(layer, (l) => l.hasVectorStyle);
}

/**
 * Traits for layers that support reordering.
 * Note that the ordering itself is not stored here.
 */
export type IsOrderable = {
    isOrderable: true;
};

/**
 * Check that the given layer has the {@link IsOrderable} trait.
 */
export function isOrderable(layer: LayerState): layer is LayerState & IsOrderable {
    return check<IsOrderable>(layer, (l) => l.isOrderable);
}

/**
 * Traits for layers with paths.
 */
export type HasPath = {
    hasPath: true;
    pathLoaded: boolean;
};

/**
 * Check that the given layer has the {@link HasPath} trait.
 */
export function hasPath(layer: LayerState): layer is LayerState & HasPath {
    return check<HasPath>(layer, (l) => l.hasPath);
}
