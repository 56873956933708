import { Box3, LineBasicMaterial, MeshBasicMaterial, Vector3 } from 'three';
import type Instance from '@giro3d/giro3d/core/Instance';
import Annotation from 'types/Annotation';
import { DatasetId } from 'types/common';
import { selectAnnotation } from 'redux/annotationActions';
import { Dispatch } from 'store';
import LayerManager from 'giro3d_extensions/LayerManager';
import HoveredItem from 'types/HoveredItem';
import type { Point } from 'geojson';
import GeometryObject, { GEOMETRY_TYPE } from '../GeometryObject';
import ThreejsGroupLayer from './ThreejsGroupLayer';
import IsClickable from './IsClickable';
import { HostView } from './Layer';
import IsHoverable from './IsHoverable';

const highlightFaceMaterial = new MeshBasicMaterial({
    color: 'yellow',
    opacity: 0.2,
});
const highlightSideMaterial = new MeshBasicMaterial({
    color: 'yellow',
    opacity: 0.6,
});
const highlightLineMaterial = new LineBasicMaterial({
    color: 'yellow',
});

const selectedFaceMaterial = new MeshBasicMaterial({
    color: 0x658a63,
    opacity: 0.2,
});
const selectedSideMaterial = new MeshBasicMaterial({
    color: 0x480f73,
    opacity: 0.6,
});
const selectedLineMaterial = new LineBasicMaterial({
    color: 0x24203d,
});

let selectedAnnotation = null;

class AnnotationLayer extends ThreejsGroupLayer implements IsClickable, IsHoverable {
    readonly isClickable = true as const;
    readonly isHoverable = true as const;
    drawObject: GeometryObject;
    annotation: Annotation;

    private static getSelectedAnnotation(): AnnotationLayer {
        return selectedAnnotation;
    }

    private static setSelectedAnnotation(layer: AnnotationLayer) {
        selectedAnnotation = layer;
    }

    constructor(
        datasetId: DatasetId,
        instance: Instance,
        dispatch: Dispatch,
        annotation: Annotation,
        layerManager: LayerManager,
        hostView: HostView
    ) {
        super({
            instance,
            getFootprint: null,
            datasetId,
            dispatch,
            layerManager,
            hostView,
        });
        this.annotation = annotation;
    }

    // eslint-disable-next-line class-methods-use-this
    protected override subscribeToStateChanges(): void {
        // Nothing to do here
    }

    protected override async initOnce() {
        await super.initOnce();
        const options = {};
        this.drawObject = new GeometryObject(this._giro3dInstance, options, this.annotation.geometry);
        if (this.annotation.geometry.type !== 'Point') this.object3d.add(this.drawObject);
        this.object3d.traverse((o) => {
            o.userData.datasetId = this.datasetId;
            o.userData.annotationId = this.annotation.id;
        });
        this.object3d.updateMatrixWorld();
        this.initialized = true;
        this.notifyLayerChange();
    }

    hover(hover: boolean): void {
        if (hover) {
            this.drawObject.setMaterials({
                faceMaterial: highlightFaceMaterial,
                sideMaterial: highlightSideMaterial,
                lineMaterial: highlightLineMaterial,
            });
        } else {
            this.drawObject.setMaterials({});
        }
        this._giro3dInstance.notifyChange();
    }

    getHoverInfo(): HoveredItem {
        return {
            name: this.annotation.name,
            itemType: 'Annotation',
        };
    }

    removeFromParent() {
        // We need to manually remove the DOM elements because otherwise they will not removed
        // from the DOM and will linger indefinitely.
        // See https://github.com/mrdoob/three.js/pull/16934
        this.drawObject.clear();
        this.object3d.removeFromParent();
    }

    protected override onDispose() {
        this.drawObject.removeFromParent();
        this.drawObject.dispose();
        this.drawObject = null;
        super.onDispose();
    }

    async clickHandler() {
        this._dispatch(selectAnnotation(selectedAnnotation === this ? null : this.annotation));
    }

    setSelected(selected = true) {
        const currentlySelected = AnnotationLayer.getSelectedAnnotation();
        if (currentlySelected) {
            // Reset materials for the previously selected annotation
            currentlySelected.drawObject.setMaterials({});
            if (currentlySelected === this) {
                AnnotationLayer.setSelectedAnnotation(null);
                return;
            }
        }
        if (selected) {
            AnnotationLayer.setSelectedAnnotation(this);
            this.drawObject.setMaterials({
                faceMaterial: selectedFaceMaterial,
                sideMaterial: selectedSideMaterial,
                lineMaterial: selectedLineMaterial,
            });
        }
    }

    isSelected() {
        return AnnotationLayer.getSelectedAnnotation() === this;
    }

    // Temporary workaround because currently annotation layers are put in the Redux store,
    // which cause huge performance issues when using the Redux profiler, as it tries to
    // serialize the object.
    // eslint-disable-next-line class-methods-use-this
    toJSON() {
        return '<not serialized>';
    }

    setGeojson(geojson) {
        this.drawObject.setGeojson(geojson);
    }

    getClickableElement() {
        return this.get3dElement();
    }

    getBoundingBox() {
        if (!this.initialized) return null;
        const getBoundingBoxTmpBox3 = new Box3();

        if (this.annotation.geometry.type === GEOMETRY_TYPE.POINT) {
            const p = this.annotation.geometry as Point;
            return getBoundingBoxTmpBox3.setFromCenterAndSize(
                new Vector3(p.coordinates[0], p.coordinates[1], p.coordinates[2] * this.settings.zScale),
                new Vector3(1, 1, 1)
            );
        }
        return getBoundingBoxTmpBox3.setFromObject(this.get3dElement());
    }
}

export default AnnotationLayer;
